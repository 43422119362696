import { getExecute } from '@/utils/dialogExecute'

export const rules = {
  title: [
    { required: true, message: '请输入资讯标题', trigger: 'change' }
  ],
  type: [
    { required: true, message: '请选择资讯类型', trigger: 'change' }
  ],
  cover: [
    { required: true, message: '请选择封面', trigger: 'change' }
  ],
  publisher: [
    { required: true, message: '请输入资讯发布者', trigger: 'change' }
  ],
  orderNo: [
    { required: true, message: '请输入资讯序号', trigger: 'change' }
  ]
  // content: [
  //   { required: true, message: '请输入资讯内容', trigger: 'change' }
  // ]
}


const singleVue = {
  addOrEditPolicy: false,
}

const strategyMap = {
  addOrEditPolicy: () => import('./components/addOrEditPolicy.vue'), // 日志显示
}

export const execute = getExecute(singleVue, strategyMap)
