export const taskConfig = {
  toolbar: [
    { name: 'styles', items: ['Font', 'FontSize'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline']
    },
    {
      name: 'paragraph',
      items: [
        'NumberedList',
        'BulletedList'
      ]
    },
    { name: 'links', items: ['Link'] },
    {
      name: 'insert',
      items: ['Image', 'Table']
    }
  ]
}
